import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import axios from "axios";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login - Rumbo S.R.L.",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "Home - Rumbo S.R.L.",
    },
  },
  {
    path: "/panel",
    component: () => import("../views/Panel.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "prospectos",
        name: "prospectos",
        component: () => import("../views/Prospectos.vue"),
        meta: {
          title: "Prospectos - Rumbo S.R.L.",
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "Login",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title =
    typeof to.meta.title === "string" ? to.meta.title : "Rumbo S.R.L.";

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      axios.defaults.headers.common = "";
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      //Actualiza el header de Axios con el User de Vuex
      if (store.getters.user) {
        axios.defaults.headers.common[store.getters.getTypeHeader] = String(store.getters.user);
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
