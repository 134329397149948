<template>
  <router-view />
</template>

<script>
// import axios from "axios";

import "@fontsource/roboto"; 
export default {
  setup() {
  },
}
</script>

<style>
html,
body,
#app {
  height: 100%;
  background: #f9f9f9;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
