import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    user: localStorage.getItem("user") || null,
    name: localStorage.getItem("name") || null,
    type: localStorage.getItem("type") || null,
    types: {
      //Interno de Vue
      admin: "admin",
      salesman: "salesman",
    },
    typesHeader: {
      // Para endpoints
      admin: "admin-id",
      salesman: "super-id",
    },
  },
  mutations: {
    LOGIN_SUCCESS(state, response) {
      state.user = response.user;
      state.type = response.type;
      state.name = response.name;

      localStorage.setItem("user", response.user);
      localStorage.setItem("type", response.type);
      localStorage.setItem("name", response.name);

      axios.defaults.headers.common = {};
      let typeHeader = state.typesHeader[response.type];
      axios.defaults.headers.common[typeHeader] = response.user;
    },
    DESTROY_TOKEN(state) {
      state.user = null;
      state.type = null;
      state.name = null;
      localStorage.setItem("user", "");
      localStorage.setItem("type", "");
      localStorage.setItem("name", "");
      axios.defaults.headers.common = "";
    },
  },
  actions: {},
  modules: {},
  getters: {
    user: (state) => state.user,
    type: (state) => state.type,
    isAuthenticated: (state) => state.type,
    getType: (state) => state.types[state.type],
    getTypeHeader: (state) => state.typesHeader[state.type],
  },
});
